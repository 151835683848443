*{
    margin: 0;
    padding: 0;
    
}
.container{
    width: 100vw;
    height: 4vh;
    background-color: rgb(57, 57, 249);
    color: white;
    text-align: center;
}

