.sidenav{
    width: 15vw;
    height: 96vh;
}
.containers{
    width: 15vw;
    height: 96vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
    background-color:rgb(0, 0, 0);
}
.name{
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin: 20px 25px;
    padding-top:10px;
}

.input1{
    color: white;
    margin: 20px 20px;
}
.input1:hover{
    background-color:rgb(109, 106, 106) ;
    cursor: pointer;
    border-radius: 7px;
    height: 30px;
}
.input2{
    color: white;
    background-color:rgb(109, 106, 106) ;
    margin: 17px 20px;
    border-radius: 7px;
    height: 30px;
    padding: 4px;
}
.bottom{
    display: flex;
    justify-content: space-between;
    margin: 20px 20px;
    color: white;
    position: relative;
    top: 120px;
}
.list2{
    width: 73px;
    display: flex;
    justify-content: right;
    border-radius: 4px;
    background-color:rgb(109, 106, 106); 
   padding-right: 5px;
}
.list3{
    width: 73px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    color: rgb(31, 4, 165);
    background-color: rgb(133, 244, 244); 
}
.verticalLine{
    width: 0.15vw;
    height: 96vh;
    background-color: rgb(109, 106, 106);
    position: relative;
    left: 14.85vw;
    top: -96vh;
}
.icon1{
    background-color: blue;
    border-radius: 50px;
    margin: -3px 10px;
}
.icon2{
    margin: -4px 0px -4px 70px;
}
.icon3{
    margin: -2px 10px;
}
.icon9{
    position: relative;
    top: 35px;
    left: -11vw;
}
.icon10{
  color:white;
}
.icon11{
    color:rgb(71, 51, 197) ;
}
.icon12{
    color: white;
    border-radius: 50px;
    background-color:rgb(65, 44, 169) ;
    position: relative;
    top: -109.2vh;
    left: 30px;
}
.icons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color:rgb(109, 106, 106);
    width: 50px;
    position: relative;
    top: -40px;
    left: 53px;
    border-radius: 10px;
}