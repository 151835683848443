.wrapper {
  width: 67vw;
  height: 96vh;
  position: relative;
  left: 15vw;
  top: -96vh;
  background-color: black;
}
.header {
  color: white;
  padding: 5px 20px;
  font-weight: bold;
}
.horizontal {
  width: 67vw;
  height: 0.15vw;
  background-color: rgb(109, 106, 106);
}
.lists{

  display: flex;
  justify-content: space-between;
    color: white;
}
.lists>:nth-child(1){
  font-weight: bold;
}
.lists1{

  width: 20vw;
  height: 4vh;
  text-align: left;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 15px;
  background-color: rgb(109, 106, 106);
  font-weight: bold;
}
.container1{
  color: rgba(255, 255, 255, 0.895);
  display: flex;
  flex-direction: row;
  
  
}
.content{
  display: flex;
  flex-direction: column;
  background-color: rgb(63, 61, 61);
  width: 19vw;
  height: 8vh;
  text-align: left;
  border-radius: 5px;
  margin: 15px;
  padding: 10px;
}
.content:hover{
  background-color: rgb(109, 106, 106);
  cursor: pointer;
}

.containt12{
  display: flex;
  flex-direction: column;
  background-color: rgb(63, 61, 61);
  width: 19vw;
  height: 8vh;
  text-align: left;
  border-radius: 5px;
  margin: 15px;
  padding: 10px;
}
.containt12:hover{
  background-color:rgb(109, 106, 106) ;
  cursor: pointer;
}
.first{
  width: 140px;
  position: relative;
  top: -25px;
  left: 5px;
  margin-left: 25px;
  font-weight: bold;
  color: white;
}
.vertivalline2{
  width: 0.20vw;
  height: 91.3vh;
  background-color: rgb(109, 106, 106);
  position: relative;
  top: -54.8vh;
  left: 66.80vw;
}
.icon4{
  width: 25px;
  height: 25px;
  position: relative;
  top: -70px;
  left: 270px;
}
.icon5{

position: relative;
top: 3px;
left: 2px;
color: rgb(214, 174, 252);
}
.icon6{
  position: relative;
  top: -142.7vh;
  left: 64vw;
  color: white;
}
.left1{
 
 width: 25px;
height: 25px;
}
.second{
  position: relative;
  top: -20px;
  left: 7px;
}
.line2{
  width: 0.20vw;
  height: 9vh;
  background-color: rgb(57, 57, 249);
  position: relative;
  top: -121.3vh;
  left: 15px;
}
.line1{
  width: 55px;
  height: 2px;
  background-color:rgb(57, 57, 249);
}